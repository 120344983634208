<template>
    <div class="container">
        <el-descriptions title="店铺详情" v-if="mec.id">
        <el-descriptions-item label="店铺名称">
          {{ mec.mechanism_name }}
          <el-tag size="small" v-if="mec.is_recommend">优选</el-tag>
        </el-descriptions-item>
        <el-descriptions-item label="店铺地址">
          {{ mec.mechanism_addr }}
        </el-descriptions-item>
        <el-descriptions-item label="负责人">
          {{ mec.contacts }}
        </el-descriptions-item>
        <el-descriptions-item label="联系电话">
          {{ mec.contact_telephone }}
        </el-descriptions-item>
        <el-descriptions-item label="经营类目">
          {{ mec.categories_child }}
        </el-descriptions-item>
        <el-descriptions-item label="状态">
          <span v-if="mec.status == 1" style="color: #23c3c4"
            ><span> 待审核</span>
          </span>
          <span v-else-if="mec.status == 2" style="color: #32cd32"
            >已通过</span
          >
          <span v-else-if="mec.status == 3" style="color: #ff6262"
            >已拒绝</span
          >
          <span v-else-if="mec.status == 4" style="color: #ff6262"
            >注销</span
          >
          <span v-else-if="mec.status == 5" style="color: #ff6262"
            >冻结</span
          >
          <el-button @click="pass()" type="danger" v-if="mec.status != 2"
            >通过审核</el-button
          >
        </el-descriptions-item>

        <el-descriptions-item label="店铺logo">
          <el-image
            style="width: 150px; height: 150px"
            :src="mec.mechanism_logo"
            fit="cover"
            :preview-src-list="[mec.mechanism_logo]"
          ></el-image>
        </el-descriptions-item>

        <el-descriptions-item label="营业执照">
          <el-image
            v-for="(pic, index) in mec.support_means.split(',')"
            style="width: 150px; height: 150px"
            :src="pic"
            fit="cover"
            :preview-src-list="[mec.support_means.split(',')]"
            :key="index"
          ></el-image>
        </el-descriptions-item>

        <el-descriptions-item label="店铺门面">
          <el-image
            v-for="(pic, index) in mec.facade_view.split(',')"
            style="width: 150px; height: 150px"
            :src="pic"
            fit="cover"
            :preview-src-list="[mec.facade_view.split(',')]"
            :key="index"
          ></el-image>
        </el-descriptions-item>

        <el-descriptions-item label="介绍图片">
          <el-image
            v-for="(pic, index) in mec.introduction_pic.split(',')"
            style="width: 150px; height: 150px"
            :src="pic"
            fit="cover"
            :preview-src-list="[mec.introduction_pic.split(',')]"
            :key="index"
          ></el-image>
        </el-descriptions-item>
      </el-descriptions>

       <el-tabs v-model="activeName" @tab-click="handleClick">
    <el-tab-pane label="用户管理" name="course">用户管理</el-tab-pane>
    <el-tab-pane label="配置管理" name="second">配置管理</el-tab-pane>
    <el-tab-pane label="角色管理" name="third">角色管理</el-tab-pane>
    <el-tab-pane label="定时任务补偿" name="fourth">定时任务补偿</el-tab-pane>
  </el-tabs>
    </div>
</template>

<script>
export default {

    data() {
        return {
            id:'',
            mec:{},
            activeName:'course'
        };
    },

    mounted() {
        this.id = this.$route.query.id
        this.getInfo()
    },

    methods: {
          getInfo(){
         let url = '/user/mastermechanism/findById'
        let data ={
         id:this.id
        }
       this.$get(url,data).then(res=>{
        let info = res.data.data
        this.mec = info
       }
       )
    },
    },
};
</script>

<style lang="scss" scoped>

</style>